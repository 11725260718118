const Modal = ({ id, title, modalSize, children }) => {
    return (
        <div className="modal fade" id={id} tabIndex={-1} aria-hidden="true">
            <div className={`modal-dialog modal-dialog-centered ${modalSize}`}>
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5">{title}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                    </div>
                    <div className="modal-body">
                        {children}
                    </div>
                </div>
            </div>
        </div>

    );
};

export default Modal;
